import React from "react";
import "../App.css";
import { Link } from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import BotonUser2 from "../userBotones/BotonUser2";
import BotonUser from "../userBotones/BotonUser";
import logohome from "../assets/imagenes/fans/logo-menu.png";



class Navigation extends React.Component {
  render() {
    const openMenu = () => {
      document.querySelector(".sidebar").classList.add("open");
    };


    return (
      <Navbar collapseOnSelect expand="lg" bg="#13141f" variant="dark">
      <Container>
        <Navbar.Brand href="#home">
          <Link to="/">
            <img src={logohome} className="icon-logo" alt="SÓLO FÁNATICOS" />
          </Link>
        </Navbar.Brand>
        <div className="menu">
        <BotonUser2/>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        </div>
        <Navbar.Collapse id="responsive-navbar-nav" >
          <Nav className="me-auto" >
          <Nav.Link href="/signin" id="Iniciar sesión" className="lista-sidebar-n"><BotonUser/></Nav.Link>
          <Nav.Link href="/" id="Inicio" className="lista-sidebar-n">Inicio</Nav.Link>
            <Nav.Link href="/como-participar" id="juegos" className="lista-sidebar-n">¿Cómo participar?</Nav.Link>
            <Nav.Link href="/#califica" className="lista-sidebar-n">Calificá</Nav.Link>
            <NavDropdown title="Calificaciones" id="collasible-nav-dropdown" className="lista-sidebar-n ">
              <NavDropdown.Item href={`/informes/hombres`} className="lista-sidebar-nsub">
                Hombres
              </NavDropdown.Item>
              <NavDropdown.Item href={`/informes/mujeres`} className="lista-sidebar-nsub">
                Mujeres
              </NavDropdown.Item>
              {/* <NavDropdown.Item href={`/informes/socios`}className="lista-sidebar-nsub" >
                Socios
              </NavDropdown.Item >
              <NavDropdown.Item href={`/informes/nosocios`}className="lista-sidebar-nsub">
               No socios
              </NavDropdown.Item> */}
              <NavDropdown.Divider />
              <NavDropdown.Item href={`/informes/general`}className="lista-sidebar-nsub">
                General
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav>
          <NavDropdown title="Documentos" id="collasible-nav-dropdown1" className="lista-sidebar-n ">
              <NavDropdown.Item href="/informes-mujeres" className="lista-sidebar-nsub">
              Términos y Condiciones
              </NavDropdown.Item>
              <NavDropdown.Item href="/informes-socios"className="lista-sidebar-nsub" >
              Política de Privacidad
              </NavDropdown.Item >
            </NavDropdown>
          
            <Nav.Link href="/#contacto" className="lista-sidebar-n">Contacto</Nav.Link>
            
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
      // <div className="navigation">
      //   <nav className="nav1" >
      //     <div className="contenedor-nav" >
            
              
              
            
      //       {/* <Tada> */}
      //         <Link to="/">
      //           <img
      //             src="../../../fans/logo-menu.png"
      //             className="icon-logo"
      //             alt="SÓLO FÁNATICOS"
      //           />
      //         </Link>
      //         {/* </Tada> */}
      //         <div className="menu33">
      //         <BotonUser2/>
           
      //       <button className="icono-button" onClick={openMenu}>
      //           <img
      //             src="../../../fans/icon-menu.png"
      //             className="icono"
      //             alt="Sólo Fanáticos"
      //           />
      //         </button>
      //         </div>
      //     </div>
      //   </nav>

      //   <div className="sidebar">
          
      //     <Sidebar />
      //   </div>
      //   <nav className="nav2">
      //     <div className="contenedor-nav2" >


      //       <Sidebar1/>

 
      //     </div>
      //   </nav>
      // </div>
    
    );
  }
}
export default Navigation;
