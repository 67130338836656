import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Slider from "react-slick";
import { fetchAuspiciantesPrem } from "../action/AuspicianteActionsPrem";



export function Auspiciantes() {
  const [loadPrem, setLoadPrem] = useState(true);
  const dispatch = useDispatch();
  const config_server = JSON.parse(localStorage.getItem("configServer"));
  const auspiciantesPrem = useSelector((state) => state.auspiciantesPrem);

  useEffect(() => {
    if (loadPrem) {
      const loadAuspiciantesPrem = () => dispatch(fetchAuspiciantesPrem());
      loadAuspiciantesPrem();
      setLoadPrem(false);
    }

  }, [auspiciantesPrem, dispatch,loadPrem]);
  console.log("auspiciantesPrem", auspiciantesPrem);
    const settings = {
        dots: true,
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
      };
       return (
              <div>
        {!auspiciantesPrem.items ? (
        <div className="imagen-home-promociones1-oculto"></div>
      ) : (
        <Slider
        {...settings}
        >
          {auspiciantesPrem.items.map((auspiciante) => {
            return (
              <img
                key={auspiciante.id_auspiciante}
                src={`${config_server.linkImageAuspiciante}${auspiciante.imagen_auspiciante}`}
                style={{width:"100%"}}
                alt="Solo fanaticos"
              />
            );
          })}
        </Slider>
      )}
      </div>
     );
    };


// export default class Auspiciantes extends Component {
//   render() {
//     const settings = {
//       dots: true,
//       fade: true,
//       infinite: true,
//       speed: 500,
//       slidesToShow: 1,
//       slidesToScroll: 1
//     };
//     return (
//       <div>
//         <h2>Fade</h2>
//         <Slider {...settings}>
//           <div>
//             <img src={baseUrl + "/abstract01.jpg"} />
//           </div>
//           <div>
//             <img src={baseUrl + "/abstract02.jpg"} />
//           </div>
//           <div>
//             <img src={baseUrl + "/abstract03.jpg"} />
//           </div>
//           <div>
//             <img src={baseUrl + "/abstract04.jpg"} />
//           </div>
//         </Slider>
//       </div>
//     );
//   }

