import React, { useEffect, useState } from "react";
import { fetchProducts } from "../action/FutbolistaActions";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { Animated } from "react-animated-css";
import Timer from "react-compound-timer";
import moment from "moment";

function TablaComponent() {
  const config_server = JSON.parse(localStorage.getItem("configServer"));
  const dispatch = useDispatch();
  const calificador = useSelector((state) => state.products);
  const [load, setLoad] = useState(true);
  const [loadCrono, setLoadCrono] = useState(true);
  const [cronometro, setCronometro] = useState(0);

  useEffect(() => {
    if (load) {
      dispatch(fetchProducts());
      setLoad(false);
    }
  }, []);


  let inicioCal;
  let display;
  let hora;
  if (calificador.items) {
    if (loadCrono == true) {
      const inicio = calificador.items.calificador.inicio_cal_calificador;
      const tiempoCrono = calificador.items.calificador.tiempo_cal_calificador;
      const fechaServer = calificador.items.fechaActual;
      
      inicioCal = moment(inicio).add(tiempoCrono, "hours");
      setCronometro(inicioCal.diff(fechaServer));

      setLoadCrono(false);
    }
    
    if(calificador.items.calificador.estado_actual_calificador == 1){
      display ="flex";
      hora = (calificador.items.calificador.hora_calificador).split(":");
      hora = hora[0]+":"+hora[1]
    } else {
      display ="none";
      hora = (calificador.items.calificador.hora_calificador).split(":");
      hora = hora[0]+":"+hora[1]
    }

    
  }
 
 console.log(calificador)
  return (
    <React.Fragment>
      {!calificador.items ? (
        <Slider />
      ) : calificador.items.calificador.length === 0 ? (
        <div></div>
      ) : (
        <Animated
          animationIn="flipInY"
          animationOut="flipOutY"
          animationInDuration={3000}
          animationOutDuration={3000}
          isVisible={true}
        >
          <div className="cuadro-verde-2">
            <h2 className="centrar datos-table">
            Fecha para votar: {calificador.items.calificador.dia_calificador.split(" ")[0].split("-").reverse().join("-")}
            </h2>
            <h2 className="centrar datos-table">
              Hora de inicio: {hora}
            </h2>
   
            <h4 className="centrar text-white">Tiene 24 hs. para votar</h4>
          </div>
          <div className="centrar time-div" style ={{display:display}}> 
            <h2 className="time">
              <Timer
                
                initialTime={cronometro}
                direction="backward"
                checkpoints={[
                  {
                    time: 0,
                    callback: () => window.location.reload("/"),
                  },
                ]}
              >
                {() => (
                  <React.Fragment>
                    <Timer.Hours />:
                    <Timer.Minutes />:
                    <Timer.Seconds />
                  </React.Fragment>
                )}
              </Timer>
         
            </h2>
          </div>
        </Animated>
      )}
    </React.Fragment>
  );
}

export default TablaComponent;
