import React, { useEffect, useState } from "react";
import { fetchProducts } from "../action/FutbolistaActions";
import "../css/calautos/css/formaciones/Cancha.css";
import "../css/calautos/css/formaciones/3.css";
import "../css/calautos/css/formaciones/6.css";
import "../css/calautos/css/formaciones/9.css";
import "../css/calautos/css/formaciones/11.css";
import "../css/calautos/css/formaciones/Suplentes.css";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import {Animated} from "react-animated-css";
// import Zoom from "react-reveal/Zoom";

function Formacion1() {
  const config_server = JSON.parse(localStorage.getItem("configServer"));
  const dispatch = useDispatch();
  const calificador = useSelector((state) => state.products);
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;


  const [load, setLoad] = useState(true);

  useEffect(() => {
    if (load) {
      const loadProducts = () => dispatch(fetchProducts());
      loadProducts();
      setLoad(false);
    }
  }, [calificador, dispatch, load]);

console.log("userinfo", calificador);
  return (
    <React.Fragment>
      {!calificador.items ? (
        <Slider />
      ) : (
        <div>

            <div
              id="cancha"
              className={calificador.items.calificador.clase_formacion}
            >
              <div id="imagen_cancha"></div>
              
              {calificador.items.formacion.map((formacion) => {
                let opacarImg ="";
                let opacarTitle="";
                let opacarCursor="";
                let habilitar = (event) => true;
                const calificados= calificador.items.calificados;
                
                for (const key in calificados) {
                  
                    const id_auto = calificados[key]["id_auto"];
                    if(id_auto === formacion.id_auto){
                      opacarImg ="opacarImg";
                      opacarTitle ="opacarTitle";
                      habilitar= (event) => event.preventDefault(true);
                      opacarCursor="opacarCursor";
                      
                      console.log("entro")
                    }
                 
                }
                console.log("calificados",formacion)
                let zindex =""
                if(formacion.id_auto !== "0"){
                  zindex = 10;
                }
                return (
                  <div
                    key={formacion.id}
                    className={`jugador titular ${formacion.id} ${opacarImg}`}
                    style={{zIndex:`${zindex}`}}
                    id={formacion.id}
                  >
                   
                   <Animated animationIn="bounceIn" animationInDelay={1500} animationOut="bounce" animationInDuration={2000} animationOutDuration={2000} isVisible={true}>
                    <Link
                      className={`link-name  ${opacarCursor}`}
                      onClick={habilitar}
                      to={`/auto/${formacion.id_auto}/${formacion.imagen_auto}`}
                    >
                      {formacion.imagen_auto === "" ? (
                        <div>
                        <img className="camiseta" src="/fans/boy1-sm.png" alt="Emprendimientos virtuales sa" style={{display:"none"}} />
                      
                        {/* <p className={ `nombreju ${opacarTitle}`} style={{display:"none"}}>
                        {formacion.nombre}
                      </p> */}
                      </div>
                        ) : (
                       <div>
                        <img
                        alt="Emprendimientos virtuales sa"
                          className="camiseta"
                          
                          src={`${config_server.linkImageAuto}${formacion.imagen_auto}`}
                        />
                        {/* <p className={ `nombreju ${opacarTitle}`}>
                        {formacion.nombre}
                      </p> */}
                      </div>
                        
                      )}

                    </Link>
                    </Animated>
                    
                  </div>
                );
              })}
            </div>
          </div>
        
      )}
    </React.Fragment>
  );
}

export default Formacion1;
