import {
  FETCH_PRODUCTS1
} from "../constants/ProductType";
import swal from 'sweetalert';
import clienteAxios from '../config/axios';


export const fetchAuspiciantes = () => async (dispatch) => {
    // dispatch({
    //   type: PRODUCT_LIST_REQUEST,
    // });
  
    try {
      const { data } = await clienteAxios.get('calautos/verAuspiciantes1');
      dispatch({ type: FETCH_PRODUCTS1, payload: data.data });  
    } catch (error) {
    //   dispatch({ type: PRODUCT_LIST_FAIL, payload: error.message }); 
        swal({
          title: "¡Ups !",
          text: error.message,
          icon: "error",
          dangerMode: true,
        })
        .then(willDelete => {
          if (willDelete) {
           window.location.replace('/');
           
          }
        });
    }
  };